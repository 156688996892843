import React, {useEffect, useState} from "react";
import {useMoralis} from "react-moralis";
import {useSetRecoilState} from "recoil";
import {loggedInAccountAtom, refState} from "@stores/account";
import {useNavigate, useParams} from "react-router-dom";
import {Modal} from "react-bootstrap";
import { connectors } from "@components/Account/config";
import Preloader from "@components/Preloader";
import {toast} from "react-hot-toast";
import btgLogo from "@assets/images/btg-logo.png";
import {useTranslation} from "react-i18next";

export default function Home() {
  const {t} = useTranslation();
  const { authenticate, isAuthenticated, isAuthenticating } = useMoralis();
  const setLoggedInAccount = useSetRecoilState(loggedInAccountAtom);
  const setRef = useSetRecoilState(refState);
  const {refId} = useParams();
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [show, setShow] = useState(false);
  const isWeb3Enabled = window.hasOwnProperty('web3');

  const handleClose = () => setShow(false);

  const handleShow = () => setShow(true);

  const login = async (provider: string) => {
    if ((isWeb3Enabled || provider !== 'injected') && !isAuthenticated) {
      setLoading(true);
      try {
        // @ts-ignore
        await authenticate({provider, signingMessage: "Log in using your wallet", chainId: process.env.REACT_APP_CHAIN_ID })
          .then(function (user) {
            console.log("logged in user:", user);
            console.log(user!.get("ethAddress"));
            setLoggedInAccount(user!.get("ethAddress"));
            setLoading(false);
            window.localStorage.setItem("connectorId", provider);
            setShow(false);
          })
          .catch(function (error) {
            console.log(error);
            setLoading(false);
          });
      } catch (e) {
        setLoading(false);
      }
    } else if (!isWeb3Enabled) {
      toast.error(
        t('error.no-web3'),
        {duration: 7000}
      );
    }
  }

  useEffect(() => {
    if (refId && parseInt(refId).toString() === refId) {
      setRef(refId);
      navigate('/');
    }
  }, [refId, setRef, navigate]);

  return (
    <div id="home">
      <img src={btgLogo} title="Build Tower Game" alt="Build Tower Game"/>

      <button className="btn btn-pink mt-4 wd-250 wd-lg-auto" onClick={handleShow} disabled={isAuthenticating || isLoading}>
        {t('home.connect')}
      </button>

      <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>
            {t('home.connect')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            {connectors.map(({ title, icon, connectorId }, key) => (
              <div
                className="col-6 mb-4 cur-pointer ht-80 d-flex flex-column align-items-center justify-content-center"
                key={key}
                onClick={() => login(connectorId)}
              >
                <img src={icon} alt={title} className="wd-50 mb-2" />
                <div className="tx-center">{title}</div>
              </div>
            ))}
          </div>
          {(isAuthenticating || isLoading) && <Preloader/>}
        </Modal.Body>
      </Modal>
    </div>
  );
}
